// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { parseMetadata, } from '@aws-amplify/core/internals/aws-client-utils';
import { AmplifyUrl, AmplifyUrlSearchParams, } from '@aws-amplify/core/internals/utils';
import { composeServiceApi } from '@aws-amplify/core/internals/aws-client-utils/composers';
import { defaultConfig } from './base';
import { assignStringVariables, buildStorageServiceError, deserializeBoolean, deserializeNumber, deserializeTimestamp, emptyArrayGuard, map, parseXmlBody, parseXmlError, s3TransferHandler, } from './utils';
const listObjectsV2Serializer = (input, endpoint) => {
    const headers = assignStringVariables({
        'x-amz-request-payer': input.RequestPayer,
        'x-amz-expected-bucket-owner': input.ExpectedBucketOwner,
    });
    const query = assignStringVariables({
        'list-type': '2',
        'continuation-token': input.ContinuationToken,
        delimiter: input.Delimiter,
        'encoding-type': input.EncodingType,
        'fetch-owner': input.FetchOwner,
        'max-keys': input.MaxKeys,
        prefix: input.Prefix,
        'start-after': input.StartAfter,
    });
    const url = new AmplifyUrl(endpoint.url.toString());
    url.search = new AmplifyUrlSearchParams(query).toString();
    return {
        method: 'GET',
        headers,
        url,
    };
};
const listObjectsV2Deserializer = async (response) => {
    if (response.statusCode >= 300) {
        // error is always set when statusCode >= 300
        const error = (await parseXmlError(response));
        throw buildStorageServiceError(error, response.statusCode);
    }
    else {
        const parsed = await parseXmlBody(response);
        const contents = map(parsed, {
            CommonPrefixes: [
                'CommonPrefixes',
                value => emptyArrayGuard(value, deserializeCommonPrefixList),
            ],
            Contents: [
                'Contents',
                value => emptyArrayGuard(value, deserializeObjectList),
            ],
            ContinuationToken: 'ContinuationToken',
            Delimiter: 'Delimiter',
            EncodingType: 'EncodingType',
            IsTruncated: ['IsTruncated', deserializeBoolean],
            KeyCount: ['KeyCount', deserializeNumber],
            MaxKeys: ['MaxKeys', deserializeNumber],
            Name: 'Name',
            NextContinuationToken: 'NextContinuationToken',
            Prefix: 'Prefix',
            StartAfter: 'StartAfter',
        });
        return {
            $metadata: parseMetadata(response),
            ...contents,
        };
    }
};
const deserializeCommonPrefixList = (output) => output.map(deserializeCommonPrefix);
const deserializeCommonPrefix = (output) => map(output, {
    Prefix: 'Prefix',
});
const deserializeObjectList = (output) => output.map(deserializeObject);
const deserializeObject = (output) => map(output, {
    Key: 'Key',
    LastModified: ['LastModified', deserializeTimestamp],
    ETag: 'ETag',
    ChecksumAlgorithm: [
        'ChecksumAlgorithm',
        value => emptyArrayGuard(value, deserializeChecksumAlgorithmList),
    ],
    Size: ['Size', deserializeNumber],
    StorageClass: 'StorageClass',
    Owner: ['Owner', deserializeOwner],
});
const deserializeChecksumAlgorithmList = (output) => output.map(entry => String(entry));
const deserializeOwner = (output) => map(output, { DisplayName: 'DisplayName', ID: 'ID' });
export const listObjectsV2 = composeServiceApi(s3TransferHandler, listObjectsV2Serializer, listObjectsV2Deserializer, { ...defaultConfig, responseType: 'text' });
